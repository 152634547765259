<template>
  <div>
    <b-card>
      <b-row class="">
        <b-col class="col-md-12">
          <h2 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1">
            {{ $t("partners") }}
          </h2>
        </b-col>
      </b-row>

    </b-card>
    <b-card>
      <b-row class="col-12">
        <h3 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mb-1">
          {{ $t("add_partner") }}
        </h3>
      </b-row>
      <b-form>
        <b-row class="col-12">
          <b-col md="4">
            <b-form-group
                id="name"
                :label="$t('name') + ':'"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model.trim="partner_name"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
                id="commission"
                :label="$t('commission') + ':'"
                label-for="commission"
            >
              <b-form-input
                  id="commission"
                  v-model.trim="percentage"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
                id="member_discount"
                :label="$t('member_discount') + ':'"
                label-for="member_discount"
            >
              <b-form-input
                  id="member_discount"
                  v-model.trim="discount"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
                id="five-fee-percent"
                :label="$t('Aequitex Fee') + ':'"
                label-for="five-fee-percent-input"
            >
              <b-form-input
                  id="five-fee-percent-input"
                  v-model.trim="hive_fee"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="" class="mt-1 pt-1">
              <b-button @click="addPartner()" variant="primary" size="sm">{{ $t('add_partner') }}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <Table :items="partners" striped :url="url" responsive hover class="position-relative" show-empty
             :fields="fields">
        <template #head()="scope">
          <div class="text-nowrap">{{ $t(scope.label) }}</div>
        </template>

        <template #cell(percentage)="data">
          <span v-if="data.item.percentage"> {{ data.item.percentage }} %</span>
        </template>
        <template #cell(discount)="data">
          <span v-if="data.item.discount"> {{ data.item.discount }} %</span>
        </template>
        <template #cell(hive_fee_percent)="data">
          <span v-if="data.item.hive_fee_percent"> {{ data.item.hive_fee_percent }} %</span>
        </template>
        <template #cell(companies)="data">
          <span v-if="data.item.companies"> {{ data.item.companies.length }} </span>
        </template>
        <template #cell(name)="data">
          <p style="" class="badge badge-light-primary" @click="$router.push(`/partner/${data.item.id}`)" role="button">
            {{ data.item.name }}
          </p>
        </template>
        <template #cell(actions)="data">
          <b-button @click="copyLink(data.item)" v-b-tooltip.hover :title="$t('copy')" variant="outline-dark" size="sm"
                    class="mr-1" :id="'copy-button-' + data.item.id"
                    ref="copyButton">
            <feather-icon icon="CopyIcon"/>
            <b-tooltip :target="'copy-button-' + data.item.id" triggers="click"
                       :show.sync="showCopiedTooltip[data.item.id]"
                       placement="top">
              Copied!
            </b-tooltip>
          </b-button>
          <b-button variant="outline-warning" size="sm" class="mr-1"
                    v-b-tooltip.hover :title="$t('report')" @click="$router.push(`/partner/${data.item.id}`)">
            <feather-icon icon="FileTextIcon"/>
          </b-button>
          <b-button v-if="data.item.status == 0" @click="activate(data.item)" variant="outline-success" size="sm"
                    class="mr-1" v-b-tooltip.hover :title="$t('activate')">
            <feather-icon icon="CheckIcon"/>
          </b-button>
          <b-button v-else-if="data.item.status == 1" @click="deactivate(data.item)" variant="outline-danger" size="sm"
                    class="mr-1" v-b-tooltip.hover :title="$t('deactivate')">
            <feather-icon icon="XIcon"/>
          </b-button>
        </template>
      </Table>
    </b-card>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.8/clipboard.min.js"></script>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BTr,
  BTh,
  BButton,
  BLink,
} from "bootstrap-vue";
import CustomLoader from "@/components/Common/CustomLoader";
import Table from "@/components/Common/Table2";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ClipboardJS from 'clipboard';

export default {
  components: {
    Table,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    CustomLoader,
  },
  directives: {
    Ripple,
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  data() {
    return {
      showCopiedTooltip: [],
      partner_name: null,
      percentage: null,
      discount: null,
      hive_fee: null,
      page: 1,
      totalItems: 0,
      url: '/partners',
      partners: null,
      fields: [
        {
          key: "name",
          label: "name",
        },
        {
          key: "percentage",
          label: "commission",
        },
        {
          key: "discount",
          label: "member_discount",
        },
        {
          key: "hive_fee_percent",
          label: "Aequitex Fee",
        },
        {
          key: "code",
          label: "code",
        },
        {
          key: "companies",
          label: "companies",
        },
        {
          key: "actions",
          label: "actions",
          aling: "end"
        },
      ],
    };
  },
  created() {
    this.getPartners();
  },
  methods: {
    getPartners() {
      this.$http
          .post(`/partners?page=` + this.page)
          .then((res) => {
            if (res) {
              this.partners = res.data.value.data;
              this.totalItems = res.data.value.total;
              this.page = res.data.value.current_page;
            }
          });
    },
    addPartner() {
      let data = {
        name: this.partner_name,
        percentage: this.percentage,
        discount: this.discount,
        hive_fee: this.hive_fee
      }
      this.$http
          .post(`/partners/store`, data)
          .then((res) => {
            if (res) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "success",
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              window.location.reload();
            }
          });
    },
    copyToClipboard(text, itemId) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.$set(this.showCopiedTooltip, itemId, true);
      setTimeout(() => {
        this.$set(this.showCopiedTooltip, itemId, false);
      }, 1000);
    },
    copyLink(item) {
      const codeToCopy = item.code;
      const linkToCopy = `terminal.aequitex.com/register?partner_code=${codeToCopy}`;
      this.copyToClipboard(linkToCopy, item.id);
    },
    activate(item) {
      this.$http
          .post(`/partners/${item.id}/activate`)
          .then((res) => {
            if (res) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "success",
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              window.location.reload();
            }
          });
    },
    deactivate(item) {
      this.$http
          .post(`/partners/${item.id}/deactivate`)
          .then((res) => {
            if (res) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "success",
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              window.location.reload();
            }
          });
    },

  },
};
</script>

<style scoped></style>
  